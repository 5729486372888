<template>
  <card style="width: 49%">
    <div class="w-full b-line p-6 rounded-lg flex flex-col gap-4">
      <p class="w-full font-bold text-base text-darkPurple tracking-wide text-left">{{ type }}</p>

      <div class="w-full flex flex-col justify-start gap-2">    
        <p class="text-sm text-jet">{{ type === "Monthly Earning" ? "Pay Date" : "Year" }}</p>
        <CSelect
          :placeholder="selectPlaceHolder"
          :options="optionsBox"
          variant="h-10"
          class="mr-2 -mt-3 w-56"
          v-model="selectModel"
          @onChange="getEarning($event)"
        />
      </div>

      <div class="w-full mx-auto flex justify-between items-center">
        <div class="flex justify-between items-center gap-2">
          <div class="w-9 h-9">
            <icon icon-name="gross-icon" size="s" class="icon-gross"/>
          </div>
          <div class="text-xs text-greysBlue">
            <p>{{ type === "Monthly Earning" ? "Gross Pay Amount" : "Total Gross Earning" }}</p>
            <p class="text-darkPurple font-bold text-lg">
                {{ type === "Monthly Earning" ? 
                convertToCurrency(earning.grossEarnings) || "Nil" :
                convertToCurrency(earning.totalGrossEarnings) || "Nil"
              }}
            </p>
          </div>
        </div>

        <div class="text-sm text-greysBlue flex flex-col justify-center items-end">
          <p>Pay Period</p>
          <p class="text-darkPurple font-semibold text-base">
            {{ type === "Monthly Earning" ?
              `${earning.payStartDate} - ${earning.payDate}` :
              `${earning.year}`
            }}
          </p>
        </div>
      </div>

      <div class="w-full border border-dashed border-romanSilver" style="border-width: 0.5px" /> 

      <div class="">
        <p class="text-darkPurple font-bold text-base">Breakdown</p>
        <div class="earning flex justify-start items-start">
          <div class="w-full h-full flex flex-col justify-center items-center" v-if="loading">
            <loader size="xxs" :loader-image="false" />
          </div>

          <div v-else class="w-full h-full flex justify-start items-center">
            <!-- <div class="relative h-full z-index"> -->
              <DonutChart
                :data="earningChart"
                :options="options"
                :styles="styles"
              />
            <!-- </div> -->
            <div class="width absolute flex justify-start items-center gap-2">
              <p class="flex flex-col">
                <span  v-for="(color, index) in earningChart.datasets[0].backgroundColor" :key="index">
                 <span class="inline-block w-3 h-3 rounded-full" :style="{backgroundColor: color}"/>
                </span>
              </p>
             
              <p class="flex flex-col text-jet">
                <span  v-for="(label, index) in earningChart.labels" :key="index">{{ label}}</span>
              </p>

              <p class="flex flex-col text-jet font-semibold">
                <span  v-for="(item, index) in earningChart.datasets[0].data" :key="index">
                  {{ type === "Monthly Earning" ? 
                      convertToCurrency(item) : `${item}%`
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
  </template>
  
  <script>

  import Card from "./Card";
  import CSelect from "@scelloo/cloudenly-ui/src/components/select";
  import DonutChart from "./donutChart";
  
  export default {
    components: {
      Card,
      CSelect,
      DonutChart
    },
    props: {
      type: String,
      earning: {},
      earningChart: {},
      loading: Boolean,
      optionsBox: [],
      selected: Number | String,
    },
    data() {
      return {
        earningSelected: "1/04/2024",
        
        options: {
          cutoutPercentage: 60,
          legend: {
            display: false,
            position: "bottom",
          },
          responsive: true,
          maintainAspectRatio: false
        },
        styles: {
          height: '120px',
          width: '300px',
          position: 'absolute',
          left:"-92px",
          zIndex:"2"
        }
      }
    },
    computed: {
      selectPlaceHolder() {
        return this.type === "Monthly Earning" ? "--Select Month--" : "--Select Year--"
      },
      selectModel: {
        get(){
          return this.type === "Monthly Earning" ? this.selected : this.selected;
        },
        set(value){
          this.earningSelected = value
        }
      }, 
    },
    methods: {
      getEarning(selected) {
        this.$emit('select', selected, this.type);
      },
    },
  };
</script>

<style scoped>
  .b-line{
    border: 1px solid #C2C7D6
  }

  .icon-gross{
    width: 100% !important;
    height: 100% !important;
  }

  .earning{
    height: 128px;
    position: relative;
    margin-top: 5px;
    z-index: 0;
  }
  .width{
    width: fit-content;
    left: 128px;
    z-index: 1;
  }
</style> 